import * as variantTransformer from "./variantTransformer"
import { cleanString, safeHeDecode } from "../../social-supermarket/util/generalUtil"
import { transformImage } from "./imageTransformer"
import {
  AttributeOptionsType,
  ImageType,
  ImpactType,
  ProductType,
  VendorType,
} from "social-supermarket-model"

export const multipleFromGatsby = (
  gatsbyProducts: any[],
  impacts: ImpactType[] | null = null
): ProductType[] => {
  return gatsbyProducts ? gatsbyProducts.map(product => mapProduct(product, impacts)) : undefined
}

export const mapProduct = (
  gatsbyProduct: any,
  impacts: ImpactType[] | null = null
): ProductType => {
  let gatsbyBrand
  let gatsbySecondaryBrand

  if (gatsbyProduct.brands?.nodes?.length > 1) {
    gatsbyBrand = gatsbyProduct.brands?.nodes[1]
    gatsbySecondaryBrand = gatsbyProduct.brands?.nodes[0]
  } else {
    gatsbyBrand = gatsbyProduct.brands?.nodes[0]
  }

  const name = safeHeDecode(gatsbyProduct.name || "")

  const gallery = transformGallery(
    gatsbyProduct.galleryImages?.nodes || [],
    gatsbyProduct.galleryOrder,
    name
  )

  return {
    productId: gatsbyProduct.productId,
    slug: gatsbyProduct.slug,
    name,
    description: gatsbyProduct.description,
    shortDescription: gatsbyProduct.shortDescription,
    status: gatsbyProduct.status,
    date: gatsbyProduct.date,
    recentSales: gatsbyProduct.recentSales,
    menuOrder: gatsbyProduct.menuOrder,
    shippingClass: getShippingClassFromProduct(gatsbyProduct),
    x: gatsbyProduct.x,
    y: gatsbyProduct.y,
    z: gatsbyProduct.z,
    remoteIds: gatsbyProduct.remoteIds,
    contents: gatsbyProduct.custom?.contents,
    shortTitle: safeHeDecode(gatsbyProduct.custom?.shortTitle || ""),
    oneLineDescription: gatsbyProduct.custom?.oneLineDescription,
    productImpacts: gatsbyProduct.productImpacts?.impactList?.map(impact => ({
      ...impact,
      icon: impacts
        ? impacts.find(({ slug }) => {
            return impact.impactIconSlug === slug
          })?.iconSvg
        : undefined,
    })),
    brand: gatsbyBrand
      ? {
          name: gatsbyBrand.name,
          slug: gatsbyBrand.slug,
          description: gatsbyBrand.description,
          impactIcons: gatsbyBrand.custom.impactIcons,
          impactOneLine: gatsbyBrand.custom.impactOneLine,
        }
      : undefined,
    vendor:
      gatsbyProduct.vendors?.nodes && gatsbyProduct.vendors?.nodes.length > 0
        ? transformVendor(gatsbyProduct.vendors?.nodes[0])
        : undefined,
    image: transformImage(gatsbyProduct.image, gatsbyProduct.name),
    imageSmall: transformImage(gatsbyProduct.imageSmall, gatsbyProduct.name),
    gallery,
    sku: gatsbyProduct.sku,
    price: gatsbyProduct.price,
    stockStatus: gatsbyProduct.stockStatus,
    productImpact: gatsbyProduct.custom?.productImpact,
    attributes: transformAttributes(gatsbyProduct.attributes?.nodes),
    variants: variantTransformer.multipleFromGatsby(gatsbyProduct.variations?.nodes),
    suppliers: gatsbyProduct.suppliers?.nodes,
    categories:
      gatsbyProduct.productCategories?.nodes?.map(({ name, slug }) => ({ name, slug })) || [],
    visibility: gatsbyProduct.visibility?.visibility || [],
    soonestDeliveryDate: gatsbyProduct.custom?.soonestDeliveryDate,
    availableCountries: ["GB"],
    hasStock: true,
  }
}

const getShippingClassFromProduct = (gatsbyProduct): string => {
  return gatsbyProduct.shippingClasses?.nodes?.length > 0
    ? gatsbyProduct.shippingClasses.nodes[0].slug
    : null
}

const transformGallery = (
  gatsbyImages: any[],
  galleryOrder: string,
  productName: string
): ImageType[] => {
  const images = gatsbyImages.map(gatsbyImage => transformImage(gatsbyImage, productName))
  return sortGallery(images, galleryOrder)
}

const sortGallery = (galleryImages: ImageType[], galleryOrder: string): ImageType[] => {
  return galleryOrder
    ? galleryOrder
        .split(",")
        .map(imageIdString => parseInt(imageIdString))
        .map(imageId => galleryImages.find(i => i.id === imageId))
        .filter(image => image)
    : galleryImages
}

const transformAttributes = (attributes: any[]): AttributeOptionsType[] => {
  return attributes
    ? attributes.map(attr => ({
        name: attr.name,
        slug: cleanString(attr.name),
        urlSlug: `attribute_${cleanString(attr.name)}`,
        options: attr.options,
      }))
    : null
}

const transformVendor = (gatsbyVendor: any): VendorType => {
  return {
    slug: gatsbyVendor.slug,
    name: gatsbyVendor.name,
    acceptsGiftNotes: gatsbyVendor.custom.acceptsGiftNotes,
    deliveryBusinessDays: gatsbyVendor.custom.deliveryBusinessDays,
    isLive: !!gatsbyVendor.custom.live,
    deliveryNotice: gatsbyVendor.custom.deliveryNotice,
    lastEventDate: gatsbyVendor.custom.lastEventDate,
    lastShipmentDate: gatsbyVendor.custom.lastShipmentDate,
    resumeShipmentDate: gatsbyVendor.custom.resumeShipmentDate,
    freezeOrders: gatsbyVendor.custom.freezeOrders,
  }
}
