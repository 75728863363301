import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { tradeGothic } from "../../constants/fonts"
import SvgIcon from "../svg/SvgIcon"
import { screenSizes } from "social-supermarket-model"
import { ImpactType } from "social-supermarket-model"

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${screenSizes.bigDesktop}px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`

const Impact = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;

  @media (max-width: ${screenSizes.tablet}px) {
    margin: 5px;
  }
`
const Name = styled.div`
  font-family: ${tradeGothic};
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;

  @media (max-width: ${screenSizes.tablet}px) {
    font-size: 0.7em;
  }
`

const Icon = styled(SvgIcon)`
  width: 50px;
  margin-right: 10px;
  svg {
    fill: white;
  }

  @media (max-width: ${screenSizes.tablet}px) {
    width: 40px;
  }
`

interface Props {
  impacts: ImpactType[]
}

const ImpactBanner: FC<Props> = ({ impacts }) => {
  return (
    <Container>
      {impacts.map(impact => (
        <Impact>
          <Icon svg={impact.plainIconSvg} />
          <Name>{impact.name}</Name>
        </Impact>
      ))}
    </Container>
  )
}

export default ImpactBanner
