import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import Carousel from "./Carousel"
import { SwiperSlide } from "swiper/react"
import ProductTaxonomyCard from "../taxonomy/ProductTaxonomyCard"
import { ProductTaxonomyType } from "social-supermarket-model"

const Container = styled.div`
  width: 100%;
`

interface Props {
  taxonomies: ProductTaxonomyType[]
}

const ProductTaxonomyDetailsCarousel: FC<Props> = ({ taxonomies }) => {
  return (
    <Container>
      <Carousel loop showArrows>
        {taxonomies.map(taxonomy => (
          <SwiperSlide key={taxonomy.name}>
            <ProductTaxonomyCard taxonomy={taxonomy} hasDescription={true} />
          </SwiperSlide>
        ))}
      </Carousel>
    </Container>
  )
}

export default ProductTaxonomyDetailsCarousel
